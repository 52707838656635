import React from "react"

//import Layout from "../components/layout"
import LayoutScene from "../components/layout-scene"

import SEO from "../components/seo"
import {Link} from "gatsby";
//import Scene from "../components/scenes/scene"
import Scene from "../components/scenes/pump-loader";

const NotFoundPage = () => (
  <LayoutScene>
    <SEO title="404: Not found" />
      <div style={{ maxWidth: `700px`, margin: `1rem auto` }}>
          <h1 style={{textAlign: 'center'}}>404: Not Found</h1>
          <p style={{textAlign: 'center'}}>It seems you are lost</p>
          <p style={{textAlign: 'center'}}><Link to={"/"}>Find the Way</Link></p>
          <Scene />
      </div>

  </LayoutScene>
)

export default NotFoundPage
/*
<h1>404: Not Found</h1>
<p>It seems you are lost.</p>
<Link to="/">Go back to the homepage</Link>
*/
